import Icon from '@/presentation/components/micro/icon'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import Button from '../../../micro/button'
import Typography from '../../../micro/typography'
import AreaLoading from '../../area-loading'
import { ListUserContacts } from '@/domain/usecases/authentication/ListUserContacts'
import { listUserContactsAction } from '@/presentation/actions/authentication/ListUserContacts'
import { Credential, LoginSteps } from '@/pages/login'
import { sendSecurityCodeAction } from '@/presentation/actions/authentication/SendSecurityCode'
import { ProfileSteps } from '@/pages/profile'
import { ToManyRequestsError } from '@/domain/erros/ToManyRequests'

interface StepEmail {
  changeStep: (props: LoginSteps | ProfileSteps) => void
  credential: Credential
}

const StepUserContacts: React.FC<StepEmail> = ({ changeStep, credential }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [contacts, setContacts] = useState<ListUserContacts.Data>({
    email: {
      available: false
    },
    phone: {
      available: false
    }
  })

  const getUserContacts = async () => {
    try {
      const { phone, email } = await listUserContactsAction({
        document: credential.document,
        email: credential.email
      })

      setContacts({
        phone,
        email
      })

      setLoading(false)
    } catch (e) {
      setError(true)
    }
  }

  useEffect(() => {
    getUserContacts()
  }, [])

  const sendSecurityCode = async (type: 'email' | 'phone') => {
    try {
      await sendSecurityCodeAction({
        document: credential.document,
        email: credential.email,
        sendTo: type
      })

      changeStep({
        step: 'securityCode',
        credential
      })
    } catch (e) {
      if (e instanceof ToManyRequestsError) {
        return changeStep({
          step: 'securityCode',
          credential
        })
      }

      console.log(e)
    }
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault()
  }

  return (
    <section className="password">
      <Typography type="h2" className="mb-5">
        Selecione um meio para enviarmos um código de segurança.
      </Typography>
      <form onSubmit={onFormSubmit}>
        {loading && !error && (
          <div className="flex flex-col gap-5">
            <AreaLoading width="w-full" height="h-20" />
            <AreaLoading width="w-full" height="h-20" />
          </div>
        )}
        {!loading && contacts.phone.available && (
          <Button variant="primary" className="w-full mt-5" onClick={() => sendSecurityCode('phone')}>
            <span className="flex justify-center gap-3 items-center w-full">
              <Icon icon="movel" size={20} color="#FFF" />
              <span className="flex flex-col">
                Enviar por SMS
                <span className="text-xs">
                  Celular final <strong>{contacts.phone.value}</strong>
                </span>
              </span>
            </span>
            <span className="transform -rotate-90">
              <Icon icon="downArrow" size={20} color="#FFF" />
            </span>
          </Button>
        )}
        {!loading && contacts.email.available && (
          <Button variant="secondary" className="w-full mt-5 gap-1" onClick={() => sendSecurityCode('email')}>
            <span className="flex justify-center gap-3 items-center w-full">
              <Icon icon="mailCheck" size={20} color="#FFF" />
              <span className="flex flex-col">
                Enviar por e-mail
                <span className="text-xs">{contacts.email.value}</span>
              </span>
            </span>
            <span className="transform -rotate-90">
              <Icon icon="downArrow" size={20} color="#FFF" />
            </span>
          </Button>
        )}
        {(!loading && !contacts.phone.available && !contacts.email.available) ||
          (error && (
            <>
              <Typography type="h2">Opa! 🖐</Typography>
              <Typography>
                Nós não encontramos um meio para enviar o seu código de segurança. Por favor, entre em contato com a
                central de atendimento e faça uma atualização cadastral.
              </Typography>
              <Link href="tel:10545">
                <a>
                  <Button variant="primary" className="mt-5 w-full">
                    Ligar agora para 105 45
                  </Button>
                </a>
              </Link>
            </>
          ))}
        <Button
          variant="gray"
          className="w-full"
          onClick={() =>
            changeStep({
              step: 'email',
              credential
            })
          }
        >
          Voltar
        </Button>
      </form>
    </section>
  )
}

export default StepUserContacts
