import { InvalidParamsError } from '@/domain/erros/InvalidParamsError'
import { ToManyRequestsError } from '@/domain/erros/ToManyRequests'
import { UnexpectedError } from '@/domain/erros/UnexpectedError'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http/HttpClient'
import { SendSecurityCode } from '@/domain/usecases/authentication/SendSecurityCode'

export class SendSecurityCodeUseCase implements SendSecurityCode {
  constructor(private readonly apiUrl: string, private readonly httpClient: HttpClient<SendSecurityCodeUseCase.Data>) {}

  async send(params: SendSecurityCode.Params): Promise<SendSecurityCode.Data> {
    const httpRequest = await this.httpClient.request({
      url: this.apiUrl,
      method: 'post',
      body: params,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    switch (httpRequest.statusCode) {
      case HttpStatusCode.ok:
        return httpRequest.body
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.toManyRequests:
        throw new ToManyRequestsError()
      default:
        throw new UnexpectedError()
    }
  }
}

export namespace SendSecurityCodeUseCase {
  export type Data = SendSecurityCode.Data
}
