import { UnexpectedError } from '@/domain/erros/UnexpectedError'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http/HttpClient'
import { VerifySecurityCode } from '@/domain/usecases/authentication/VerifySecurityCode'
import { InvalidSecurityCodeError } from '@/domain/erros/InvalidSecurityCode'

export class VerifySecurityCodeUseCase implements VerifySecurityCode {
  constructor(
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient<SendSecurityCodeUseCase.Message>
  ) {}

  async verify(params: VerifySecurityCode.Params): Promise<boolean> {
    const httpRequest = await this.httpClient.request({
      url: this.apiUrl,
      method: 'post',
      body: params,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    switch (httpRequest.statusCode) {
      case HttpStatusCode.ok:
        return true
      case HttpStatusCode.forbidden:
        throw new InvalidSecurityCodeError()
      default:
        throw new UnexpectedError()
    }
  }
}

export namespace SendSecurityCodeUseCase {
  export type Message = VerifySecurityCode.Message
}
