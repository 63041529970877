import Loading from '@/presentation/components/micro/loading'
import React, { useState } from 'react'
import Button from '../../../micro/button'
import Input from '../../../micro/input'
import Typography from '../../../micro/typography'
import { Credential, LoginSteps } from '@/pages/login'
import { authenticationAction } from '@/presentation/actions/authentication/Authentication'
import { useAuthContext } from '@/presentation/contexts/AuthContext'

interface StepPassword {
  changeStep: (props: LoginSteps) => void
  credential: Credential
}

const StepPassword: React.FC<StepPassword> = ({ changeStep, credential }) => {
  const [loading, setLoading] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [password, setPassword] = useState('')

  const { login } = useAuthContext()

  const onFormSubmit = e => {
    e.preventDefault()
  }

  const onLogin = async (): Promise<void> => {
    try {
      setLoading(true)

      const auth = await authenticationAction({
        document: credential.document,
        email: credential.email,
        password
      })

      auth && login(JSON.stringify(auth))
    } catch (e) {
      setLoading(false)
      setInvalidPassword(true)
    }
  }

  const forgotPassword = async (): Promise<void> => {
    changeStep({
      step: 'contacts',
      credential
    })
  }

  const onChange = (text: string) => {
    setInvalidPassword(false)
    setPassword(text)
  }

  return (
    <section className="password">
      {!invalidPassword && (
        <Typography type="h2" className="mb-5">
          Agora, digite a sua senha.
        </Typography>
      )}
      {invalidPassword && (
        <Typography type="h2" className="mb-5 text-secondary">
          Ops! Parece que seu e-mail ou senha estão incorretos.
        </Typography>
      )}
      <form onSubmit={onFormSubmit}>
        <Input
          placeHolder="Senha"
          className={invalidPassword && 'active'}
          type="password"
          onClick={(e: any) => console.log(e)}
          onChange={e => onChange(e.target.value)}
        />
        <Button variant="primary" className="w-full mt-5" onClick={() => onLogin()}>
          {loading && <Loading />} Entrar
        </Button>
        <Button variant="gray" className="w-full" onClick={forgotPassword}>
          Esqueci minha senha
        </Button>
        <Button
          variant="gray"
          className="w-full"
          onClick={() =>
            changeStep({
              step: 'email',
              credential
            })
          }
        >
          Voltar
        </Button>
      </form>
    </section>
  )
}

export default StepPassword
