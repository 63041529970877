import { InvalidCredentialsError } from '@/domain/erros/InvalidCredentialsError'
import { UnexpectedError } from '@/domain/erros/UnexpectedError'
import { Authentication } from '@/domain/usecases/authentication/Authentication'
import { ChangePasswordError } from '@/domain/erros/ChangePasswordError'
import { InvalidParamsError } from '@/domain/erros/InvalidParamsError'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http/HttpClient'

export class AuthenticationUseCase implements Authentication {
  constructor(
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient<AuthenticationUseCase.Account>
  ) {}

  async auth(params: Authentication.Params): Promise<Authentication.Account> {
    const httpRequest = await this.httpClient.request({
      url: this.apiUrl,
      method: 'post',
      body: params,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    switch (httpRequest.statusCode) {
      case HttpStatusCode.ok:
        return httpRequest.body
      case HttpStatusCode.forbidden:
        throw new ChangePasswordError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      default:
        throw new UnexpectedError()
    }
  }
}

export namespace AuthenticationUseCase {
  export type Account = Authentication.Account
}
