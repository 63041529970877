import React, { useEffect, useState } from 'react'
import Logo from '../presentation/components/micro/logo'
import Box from '../presentation/components/micro/box'
import StepEmail from '../presentation/components/container/login/steps/email'
import StepUserContacts from '@/presentation/components/container/login/steps/user-contacts'
import StepPassword from '../presentation/components/container/login/steps/password'
import StepNewPassword from '../presentation/components/container/login/steps/new-password'
import SecurityCodeStep from '../presentation/components/container/login/steps/security-code'

import cookie from 'js-cookie'
import router from 'next/router'

export interface Credential {
  email?: string
  document?: string
  securityCode?: number
}

export interface LoginSteps {
  step: 'email' | 'contacts' | 'password' | 'newPassword' | 'securityCode'
  credential: Credential
}

export default function Login() {
  const [step, setStep] = useState<LoginSteps>({
    step: 'email',
    credential: {
      email: undefined,
      document: undefined,
      securityCode: undefined
    }
  })

  useEffect(() => {
    if (cookie.get('auth')) {
      router.push('/')
    }
  }, [])

  const changeStepTo = (props: LoginSteps): void => {
    return setStep(props)
  }

  return (
    <main className="container mx-auto items-center flex flex-col justify-center self-center h-screen">
      <Logo size={150} />
      <Box variant="gray" className="mt-5 border-none rounded w-full relative lg:w-1/2 xl:w-1/3">
        {step.step === 'email' && <StepEmail changeStep={changeStepTo} />}
        {step.step === 'contacts' && <StepUserContacts changeStep={changeStepTo} credential={step.credential} />}
        {step.step === 'password' && <StepPassword changeStep={changeStepTo} credential={step.credential} />}
        {step.step === 'securityCode' && (
          <SecurityCodeStep changeStep={changeStepTo} credential={step.credential} stepTo="newPassword" />
        )}
        {step.step === 'newPassword' && <StepNewPassword isFirstLogin credential={step.credential} />}
      </Box>
    </main>
  )
}
