import React, { useRef, useState } from 'react'
import Button from '../../../micro/button'
import Typography from '../../../micro/typography'
import Countdown, { zeroPad } from 'react-countdown'
import Loading from '@/presentation/components/micro/loading'
import { Credential, LoginSteps } from '@/pages/login'
import { verifySecurityCodeAction } from '@/presentation/actions/authentication/VerifySecurityCode'
import { ProfileSteps } from '@/pages/profile'

interface SecurityCodeStep {
  changeStep: (props: LoginSteps | ProfileSteps) => void
  credential: Credential
  stepTo: 'newPassword' | 'newEmail'
}

const SecurityCodeStep: React.FC<SecurityCodeStep> = ({ changeStep, credential, stepTo }) => {
  const [allowSendSecurityCode, setAllowSendSecurityCode] = useState(false)
  const [securityCodeInvalid, setSecurityCodeInvalid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const firstInput = useRef(null)
  const secondInput = useRef(null)
  const thirdInput = useRef(null)
  const fourthInput = useRef(null)
  const fifthInput = useRef(null)
  const sixthInput = useRef(null)

  const inputs = [firstInput, secondInput, thirdInput, fourthInput, fifthInput, sixthInput]

  let countBackspace = 0

  const onChange = (ref, e) => {
    setSecurityCodeInvalid(false)

    if (inputs[ref].current.value !== '') {
      if (ref !== 5 && e.key !== 'Backspace') {
        inputs[ref + 1].current.focus()
      }
    } else {
      if (e.key === 'Backspace' && ref !== 0) {
        countBackspace++

        if (countBackspace === 1) {
          inputs[ref - 1].current.value = ''
          inputs[ref - 1].current.focus()

          countBackspace = 0
        }
      }
    }
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setAllowSendSecurityCode(true)
      return <></>
    } else {
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      )
    }
  }

  const onSubmit = async () => {
    const securityCode =
      firstInput.current.value +
      secondInput.current.value +
      thirdInput.current.value +
      fourthInput.current.value +
      fifthInput.current.value +
      sixthInput.current.value

    const securityCodeToInteger = parseInt(securityCode)

    try {
      setIsLoading(true)

      const verifyCode = await verifySecurityCodeAction({
        securityCode: securityCodeToInteger,
        document: credential.document,
        email: credential.email
      })

      verifyCode &&
        changeStep({
          step: stepTo,
          credential: {
            ...credential,
            securityCode: securityCodeToInteger
          }
        })
    } catch (e) {
      setIsLoading(false)

      setSecurityCodeInvalid(true)
    }
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault()
  }

  return (
    <section className="security">
      {!securityCodeInvalid && (
        <Typography type="h2" className="mb-5">
          Digite abaixo o código que enviamos para você.
        </Typography>
      )}
      {securityCodeInvalid && (
        <Typography type="h2" className="mb-5 text-secondary">
          Você digitou um código de segurança inválido. Tente novamente.
        </Typography>
      )}
      <form onSubmit={onFormSubmit}>
        <div className={`${securityCodeInvalid ? 'active' : ''} flex flex-row`}>
          {inputs.map((input, index) => (
            <input
              key={index}
              onClick={(e: any) => console.log(e)}
              maxLength={1}
              onKeyDown={e => onChange(index, e)}
              className="form-input block w-full h-16 p-3 lg:p-5 rounded outline-none font-head border-2 border-gray text-center"
              type="tel"
              ref={input}
            />
          ))}
        </div>
        <div>
          <Button variant="primary" className="w-full mt-5 mb-0" onClick={onSubmit}>
            {isLoading && <Loading />} Continuar
          </Button>
        </div>
      </form>
      {!allowSendSecurityCode && (
        <Button variant="gray" className="w-full">
          Reenviar código (<Countdown date={Date.now() + 300000} renderer={renderer} />)
        </Button>
      )}
      {allowSendSecurityCode && (
        <Button
          variant="gray"
          className="w-full"
          onClick={() => {
            setAllowSendSecurityCode(false)
          }}
        >
          Reenviar código agora
        </Button>
      )}
    </section>
  )
}

export default SecurityCodeStep
