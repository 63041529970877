import React, { useState } from 'react'
import Button from '../../../micro/button'
import Input from '../../../micro/input'
import Typography from '../../../micro/typography'
import Loading from '@/presentation/components/micro/loading'
import { cpf, cnpj } from 'cpf-cnpj-validator'
import { authenticationAction } from '@/presentation/actions/authentication/Authentication'
import { ChangePasswordError } from '@/domain/erros/ChangePasswordError'
import { LoginSteps } from '@/pages/login'

import validator from 'validator'

interface StepEmail {
  changeStep: (props: LoginSteps) => void
}

const StepEmail: React.FC<StepEmail> = ({ changeStep }) => {
  const [loginCredential, setLoginCredential] = useState('')
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const validateEmail = (email: string) => {
    return validator.isEmail(email)
  }

  const validateDocument = (document: string) => {
    return cpf.isValid(document) || cnpj.isValid(document)
  }

  const onChange = (credential: string) => {
    setError(false)
    setLoginCredential(credential)
  }

  const changeStepToChangePasswordOrLogin = async (document: string, email: string): Promise<void> => {
    try {
      setIsLoading(true)

      await authenticationAction({
        email,
        document,
        password: 'anyPassword'
      })

      return changeStep({
        step: 'password',
        credential: {
          email,
          document
        }
      })
    } catch (e) {
      if (e instanceof ChangePasswordError) {
        setIsLoading(false)

        return changeStep({
          step: 'contacts',
          credential: {
            email,
            document
          }
        })
      }

      return changeStep({
        step: 'password',
        credential: {
          email,
          document
        }
      })
    }
  }

  const onSubmit = () => {
    const emailIsValid = validateEmail(loginCredential)
    const documentIsValid = validateDocument(loginCredential)

    if (documentIsValid) {
      return changeStepToChangePasswordOrLogin(loginCredential, '')
    }
    if (emailIsValid) {
      return changeStepToChangePasswordOrLogin('', loginCredential)
    }

    return setError(true)
  }

  const onFormSubmit = e => {
    e.preventDefault()
  }

  const openNewPage = () => {
    window.open('https://central-assinante.liguetelecom.com.br/')
  }

  return (
    <section className="login">
      <Typography type="h2" className="mb-5">
        Olá!
      </Typography>
      <Typography type="h2" className="mb-5">
        A sua Central do Assinante mudou!
      </Typography>
      <Typography type="h2" className="mb-5">
        Para acessar você precisa cadastrar uma nova senha.
      </Typography>
      <Typography type="h2" className="mb-5">
        Por favor, siga os passos abaixo:
      </Typography>
      <ul>
        <li className="mb-5">- Acesse a Central do Assinante</li>
        <li className="mb-5">- Clique em &ldquo;Esqueceu sua senha?&rdquo;</li>
        <li className="mb-5">- Insira o token recebido por e-mail|SMS</li>
        <li className="mb-5">- Cadastre a sua nova senha</li>
      </ul>

      <form onSubmit={onFormSubmit}>
        {/* <Input
          placeHolder="E-mail, CPF ou CNPJ"
          onClick={(e: any) => console.log(e)}
          onChange={e => onChange(e.target.value)}
          className={error && 'active'}
        /> */}
        <Button variant="primary" className="w-full mt-5" onClick={openNewPage}>
          Ir para Central do Assinante
        </Button>
      </form>
    </section>
  )
}

export default StepEmail
