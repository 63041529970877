import Loading from '@/presentation/components/micro/loading'
import React, { useState } from 'react'
import Button from '../../../micro/button'
import Input from '../../../micro/input'
import Typography from '../../../micro/typography'
import { Credential, LoginSteps } from '@/pages/login'
import { updatePasswordAction } from '@/presentation/actions/authentication/UpdatePassword'
import { useAuthContext } from '@/presentation/contexts/AuthContext'
import { authenticationAction } from '@/presentation/actions/authentication/Authentication'
import { ProfileSteps } from '@/pages/profile'

interface StepEmail {
  changeStep?: (props: LoginSteps | ProfileSteps) => void
  credential: Credential
  isFirstLogin?: boolean
}

const StepNewPassword: React.FC<StepEmail> = ({ changeStep, credential, isFirstLogin }) => {
  const [newPassword, setNewPassword] = useState({
    password: '',
    repeatPassword: ''
  })
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { login } = useAuthContext()

  const onFormSubmit = e => {
    e.preventDefault()
  }

  const setPassword = (password: string): void => {
    setError('')
    setNewPassword({ password: password, repeatPassword: newPassword.repeatPassword })
  }

  const setRepeatPassword = (password: string): void => {
    setError('')
    setNewPassword({ repeatPassword: password, password: newPassword.password })
  }

  const onSubmit = async () => {
    if (newPassword.password !== newPassword.repeatPassword) {
      return setError('As senhas devem ser iguais. Tente novamente.')
    }
    if (newPassword.password.length < 6) {
      return setError('A senha deve conter pelo menos 6 caracteres.')
    }

    try {
      setIsLoading(true)

      await updatePasswordAction({
        document: credential.document,
        email: credential.email,
        securityCode: credential.securityCode,
        newPassword: newPassword.password
      })

      const auth = await authenticationAction({
        document: credential.document,
        email: credential.email,
        password: newPassword.password
      })

      if (!isFirstLogin) {
        return changeStep({
          step: 'success',
          credential
        })
      }

      auth && login(JSON.stringify(auth))
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <section className="new-password">
      {!error && isFirstLogin && (
        <Typography type="h2" className="mb-5">
          Para a sua segurança, identificamos que você precisa atualizar a sua senha.
        </Typography>
      )}
      {!error && !isFirstLogin && (
        <Typography type="h2" className="mb-5">
          Digite e repita a sua nova senha.
        </Typography>
      )}
      {error && (
        <Typography type="h2" className="mb-5 text-secondary">
          {error}
        </Typography>
      )}
      <form onSubmit={onFormSubmit}>
        <div className={error ? 'active' : ''}>
          <Input
            placeHolder="Nova senha"
            type="password"
            onClick={(e: any) => console.log(e)}
            onChange={e => setPassword(e.target.value)}
            className="mb-5"
            maxLength={16}
          />
          <Input
            placeHolder="Repita a nova senha"
            type="password"
            onClick={(e: any) => console.log(e)}
            onChange={e => setRepeatPassword(e.target.value)}
            className="mb-5"
            maxLength={16}
          />
        </div>
        <Button variant="primary" className="w-full" onClick={onSubmit}>
          {isLoading && <Loading />} Salvar
        </Button>
      </form>
    </section>
  )
}

export default StepNewPassword
