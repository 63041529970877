import { UnexpectedError } from '@/domain/erros/UnexpectedError'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http/HttpClient'
import { InvalidSecurityCodeError } from '@/domain/erros/InvalidSecurityCode'
import { UpdatePassword } from '@/domain/usecases/authentication/UpdatePassword'
import { InvalidParamsError } from '@/domain/erros/InvalidParamsError'

export class UpdatePasswordUseCase implements UpdatePassword {
  constructor(private readonly apiUrl: string, private readonly httpClient: HttpClient<UpdatePassword.Message>) {}

  async update(params: UpdatePassword.Params): Promise<boolean> {
    const httpRequest = await this.httpClient.request({
      url: this.apiUrl,
      method: 'put',
      body: params,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    switch (httpRequest.statusCode) {
      case HttpStatusCode.ok:
        return true
      case HttpStatusCode.forbidden:
        throw new InvalidSecurityCodeError()
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      default:
        throw new UnexpectedError()
    }
  }
}

export namespace SendSecurityCodeUseCase {
  export type Message = UpdatePassword.Message
}
